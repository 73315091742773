<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
            ref="refRetiradasAnuaisTable"
            dense
            item-key="row-key"
            disable-pagination
            :hide-default-footer="true"
            :group-by="filtros.agrupamento === 0? 'producao': null"
            :headers="headers"
            :items="dataTabela"
            :loading="loading"
            :no-data-text="noData"
            :items-per-page="$dataTableConfig.getItemsPerPageCustom()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :mobile-breakpoint="0"
          >
            <template v-slot:[`body.append`]="{ items }">
              <tr>
                <th v-for="header in headers" :class="header.class" :style="{ 'text-align': !header.align ? 'left' : header.align }">
                  <span v-if="header.total === 'title'">Total</span>
                  <span v-else-if="header.total === 'sum'" class="num_margin">{{ typeof header.totalFormat === 'function'? header.totalFormat(dataTabela.reduce((sum, item) => item[header.value] + sum, 0)): dataTabela.reduce((sum, item) => item[header.value] + sum, 0) }}</span>
                  <span v-else-if="header.total === 'sum_object'" class="num_margin d-block" v-html="header.totalFormat(header.reduceObject(dataTabela))"></span>
                  <span v-else-if="header.total === 'count'" class="num_margin">{{ typeof header.totalFormat === 'function'? header.totalFormat(dataTabela.length): dataTabela.length }}</span>
                  <span v-else-if="header.total === 'avg'" class="num_margin">{{ dataTabela.length > 0 ? (typeof header.totalFormat === 'function'? header.totalFormat(dataTabela.reduce((sum, item) => item[header.value] + sum, 0) / dataTabela.length): dataTabela.reduce((sum, item) => item[header.value] + sum, 0) / dataTabela.length) : 0 }}</span>
                  <span v-else-if="header.total === 'text'">
                    O
                    <br>
                    <strong>C</strong>
                  </span>
                  <span v-else>&nbsp;</span>
                </th>
              </tr>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              />
            </template>
            <template v-slot:[`group.header`]="{ group, isOpen, toggle }">
              <td colspan="1" style="font-size: 10px;padding-right: 0;">
                <v-btn text icon small @click="toggle" class="cursor-pointer">
                  <v-icon>
                    {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                </v-btn>
                {{ group }}
              </td>
              <td colspan="1" class="text-right num_margin" v-if="headers.filter((header) => header.value === 'area' && header.ativado).length > 0">
                <span class="text-right num_margin d-flex flex-column">
                  {{ $stringFormat.formatNumber(calculaArea(group), 0) }}
                </span>
              </td>

              <td colspan="1" class="text-center" v-if="headers.filter((header) => header.value === 'retiradas' && header.ativado).length > 0">
                O
                <br>
                <strong>C</strong>
              </td>

              <template  v-for="(mes, nomeCampo) in calculaMeses(group)">
                <td colspan="1" class="text-right num_margin" v-bind:key="mes" v-if="headers.filter((header) => header.value === nomeCampo && header.ativado).length > 0">
                  <span class="text-right num_margin d-flex flex-column">
                    {{ $stringFormat.formatNumber(mes.ofertado, 0, 4) }}
                  </span>
                  <strong class="text-right num_margin d-flex flex-column">
                    {{ $stringFormat.formatNumber(mes.colhido, 0, 4) }}
                  </strong>
                </td>
              </template>

              <template  v-for="(total, nomeCampo) in calculaTotal(group)">
                <td colspan="1" class="text-right num_margin" v-bind:key="total" v-if="headers.filter((header) => header.value === nomeCampo && header.ativado).length > 0">
                  <span class="text-right num_margin d-flex flex-column">
                    {{ $stringFormat.formatNumber(total.ofertado, 0, 2) }}
                  </span>
                  <strong class="text-right num_margin d-flex flex-column">
                    {{ $stringFormat.formatNumber(total.colhido, 0, 2) }}
                  </strong>
                </td>
              </template>
            </template>
            <template v-slot:[`item.data_abertura`]="{ item }">
              <span>{{ validDate(item.data_abertura) }}</span>
            </template>

            <template v-slot:[`item.data_fechamento`]="{ item }">
              <span>{{ validDate(item.data_fechamento) }}</span>
            </template>

            <template v-slot:[`item.area`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.area, 0) }}</span>
            </template>
            <template v-slot:[`item.retiradas`]="{ item }">
              <span>
                O
                <br>
                <strong>C</strong>
              </span>
            </template>

            <template v-slot:[`item.janeiro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.janeiro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.janeiro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.fevereiro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.fevereiro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.fevereiro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.marco`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.marco, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.marco: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.abril`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.abril, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.abril: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.maio`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.maio, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.maio: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.junho`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.junho, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.junho: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.julho`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.julho, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.julho: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.agosto`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.agosto, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.agosto: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.setembro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.setembro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.setembro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.outubro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.outubro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.outubro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.novembro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.novembro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.novembro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.dezembro`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.dezembro, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.dezembro: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.total, 0) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.total: 0, 0) }} </strong>
                  </span>
            </template>
            <template v-slot:[`item.percentual`]="{ item }">
                  <span class="num_margin">
                    <span class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.ofertado.total / item.area * 100, 0, 2) }} </span>
                    <strong class="text-right num_margin d-flex flex-column"> {{ $stringFormat.formatNumber(item.colhido ? item.colhido.total / item.area * 100: 0, 0, 2) }} </strong>
                  </span>
            </template>
            <template slot="footer">
              <v-row class="mt-5">
                <v-col class="col-12">
                  Última Atualização:
                  <strong>{{ $stringFormat.dateISOToBRL(lastUpdate.updated_at, '-') }}</strong>
                  <br>
                  Realizada por: <strong>{{ lastUpdate.username }}</strong>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

export default {
  props: {
    headers: {
      type: Array,
    },
    filtros: {
      type: Object,
    },
  },
  data: () => ({
    // General
    loading: false,
    loading1: false,
    loading2: false,
    noData: 'Nenhuma informação encontrada',

    // Form Data
    data: [],
    dataOfertado: [],
    dataColhido: [],
    lastUpdate: { username: '-', updated_at: '' },
  }),
  computed: {
    dataTabela () {
      if (this.loading) {
        return []
      }

      if (this.filtros.agrupamento === 0) {
        return this.dataTabelaTalhao
      }

      return this.dataTabelaVariedade
    },
    dataTabelaTalhao () {
      const ofertados = this.dataOfertaTalhao
      const colhidos = this.dataColhidoTalhao
      const tabela = []

      ofertados.map((ofertado) => {
        const colhido = colhidos.find((colhido) => {
          return colhido.talhao_id === ofertado.talhao_id
        })

        tabela.push(this.gerarOfertado(ofertado, colhido))
      })

      const totalProducao = tabela.reduce(function (resultado, item) {
        resultado[item.producao] = parseFloat(item.oferta.total) + parseFloat(item.colhido.total) + (resultado[item.producao] ?? 0)
        return resultado
      }, {})

      return tabela.filter(function (item) {
        return totalProducao[item.producao] > 0
      })
    },
    dataTabelaVariedade () {
      const ofertados = this.dataOfertaVariedade
      const colhidos = this.dataColhidoVariedade
      const tabela = []

      ofertados.map((ofertado) => {
        const colhido = colhidos.find((colhido) => {
          return colhido.variedade_id === ofertado.variedade_id
        })

        tabela.push(this.gerarOfertado(ofertado, colhido))
      })

      return tabela
    },
    dataOfertaTalhao () {
      const dataOfertado = this.dataOfertado.sort(this.ordenaTalhao)
      const ofertado = []

      this.$array.groupBy(dataOfertado, (item) => {
        return item.talhao_id
      }).forEach((item) => {
        ofertado.push({
          empresa: item[0].empresa,
          empresa_id: parseInt(item[0].empresa_id),
          producao: item[0].producao,
          producao_id: parseInt(item[0].producao_id),
          talhao: item[0].talhao,
          talhao_id: parseInt(item[0].talhao_id),
          area: item[0].area,
          oferta: {
            janeiro: item.reduce((sum, itemReduce) => itemReduce.mes === 1 ? parseFloat(itemReduce.ofertado) : sum, 0),
            fevereiro: item.reduce((sum, itemReduce) => itemReduce.mes === 2 ? parseFloat(itemReduce.ofertado) : sum, 0),
            marco: item.reduce((sum, itemReduce) => itemReduce.mes === 3 ? parseFloat(itemReduce.ofertado) : sum, 0),
            abril: item.reduce((sum, itemReduce) => itemReduce.mes === 4 ? parseFloat(itemReduce.ofertado) : sum, 0),
            maio: item.reduce((sum, itemReduce) => itemReduce.mes === 5 ? parseFloat(itemReduce.ofertado) : sum, 0),
            junho: item.reduce((sum, itemReduce) => itemReduce.mes === 6 ? parseFloat(itemReduce.ofertado) : sum, 0),
            julho: item.reduce((sum, itemReduce) => itemReduce.mes === 7 ? parseFloat(itemReduce.ofertado) : sum, 0),
            agosto: item.reduce((sum, itemReduce) => itemReduce.mes === 8 ? parseFloat(itemReduce.ofertado) : sum, 0),
            setembro: item.reduce((sum, itemReduce) => itemReduce.mes === 9 ? parseFloat(itemReduce.ofertado) : sum, 0),
            outubro: item.reduce((sum, itemReduce) => itemReduce.mes === 10 ? parseFloat(itemReduce.ofertado) : sum, 0),
            novembro: item.reduce((sum, itemReduce) => itemReduce.mes === 11 ? parseFloat(itemReduce.ofertado) : sum, 0),
            dezembro: item.reduce((sum, itemReduce) => itemReduce.mes === 12 ? parseFloat(itemReduce.ofertado) : sum, 0),
            total: item.reduce((sum, itemReduce) => parseFloat(itemReduce.ofertado) + sum, 0),
          },
        })
      })

      return ofertado
    },
    dataOfertaVariedade () {
      const dataOfertado = this.dataOfertado.sort(this.ordenaVariedade)
      const ofertado = []
      const areaTalhoesId = []

      this.$array.groupBy(dataOfertado, (item) => {
        return item.variedade_id
      }).forEach((item) => {
        ofertado.push({
          variedade: item[0].variedade,
          variedade_id: parseInt(item[0].variedade_id),
          area: item.filter((item) => {
            if (areaTalhoesId.indexOf(item.talhao_id) >= 0) {
              return false
            }

            areaTalhoesId.push(item.talhao_id)
            return true
          }).reduce((sum, itemReduce) => parseFloat(itemReduce.area) + sum, 0),
          oferta: {
            janeiro: item.reduce((sum, itemReduce) => itemReduce.mes === 1 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            fevereiro: item.reduce((sum, itemReduce) => itemReduce.mes === 2 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            marco: item.reduce((sum, itemReduce) => itemReduce.mes === 3 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            abril: item.reduce((sum, itemReduce) => itemReduce.mes === 4 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            maio: item.reduce((sum, itemReduce) => itemReduce.mes === 5 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            junho: item.reduce((sum, itemReduce) => itemReduce.mes === 6 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            julho: item.reduce((sum, itemReduce) => itemReduce.mes === 7 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            agosto: item.reduce((sum, itemReduce) => itemReduce.mes === 8 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            setembro: item.reduce((sum, itemReduce) => itemReduce.mes === 9 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            outubro: item.reduce((sum, itemReduce) => itemReduce.mes === 10 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            novembro: item.reduce((sum, itemReduce) => itemReduce.mes === 11 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            dezembro: item.reduce((sum, itemReduce) => itemReduce.mes === 12 ? parseFloat(itemReduce.ofertado) + sum : sum, 0),
            total: item.reduce((sum, itemReduce) => parseFloat(itemReduce.ofertado) + sum, 0),
          },
        })
      })

      return ofertado
    },
    dataColhidoTalhao () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const dataColhido = this.dataColhido.sort(this.ordenaTalhao)
      const colhido = []

      this.$array.groupBy(dataColhido, (item) => {
        return item.talhao_id
      }).forEach((item) => {
        colhido.push({
          empresa: item[0].empresa,
          empresa_id: parseInt(item[0].empresa_id),
          producao: item[0].producao,
          producao_id: parseInt(item[0].producao_id),
          talhao: item[0].talhao,
          talhao_id: parseInt(item[0].talhao_id),
          colhido: {
            janeiro: item.reduce((sum, itemReduce) => itemReduce.mes === 1 ? parseFloat(itemReduce.colhido) : sum, 0),
            fevereiro: item.reduce((sum, itemReduce) => itemReduce.mes === 2 ? parseFloat(itemReduce.colhido) : sum, 0),
            marco: item.reduce((sum, itemReduce) => itemReduce.mes === 3 ? parseFloat(itemReduce.colhido) : sum, 0),
            abril: item.reduce((sum, itemReduce) => itemReduce.mes === 4 ? parseFloat(itemReduce.colhido) : sum, 0),
            maio: item.reduce((sum, itemReduce) => itemReduce.mes === 5 ? parseFloat(itemReduce.colhido) : sum, 0),
            junho: item.reduce((sum, itemReduce) => itemReduce.mes === 6 ? parseFloat(itemReduce.colhido) : sum, 0),
            julho: item.reduce((sum, itemReduce) => itemReduce.mes === 7 ? parseFloat(itemReduce.colhido) : sum, 0),
            agosto: item.reduce((sum, itemReduce) => itemReduce.mes === 8 ? parseFloat(itemReduce.colhido) : sum, 0),
            setembro: item.reduce((sum, itemReduce) => itemReduce.mes === 9 ? parseFloat(itemReduce.colhido) : sum, 0),
            outubro: item.reduce((sum, itemReduce) => itemReduce.mes === 10 ? parseFloat(itemReduce.colhido) : sum, 0),
            novembro: item.reduce((sum, itemReduce) => itemReduce.mes === 11 ? parseFloat(itemReduce.colhido) : sum, 0),
            dezembro: item.reduce((sum, itemReduce) => itemReduce.mes === 12 ? parseFloat(itemReduce.colhido) : sum, 0),
            total: item.reduce((sum, itemReduce) => parseFloat(itemReduce.colhido) + sum, 0),
          },
        })
      })

      return colhido
    },
    dataColhidoVariedade () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const dataColhido = this.dataColhido.sort(this.ordenaVariedade)
      const colhido = []

      this.$array.groupBy(dataColhido, (item) => {
        return item.variedade_id
      }).forEach((item) => {
        colhido.push({
          variedade: item[0].variedade,
          variedade_cientifica: item[0].variedade_cientifica,
          variedade_id: parseInt(item[0].variedade_id),
          colhido: {
            janeiro: item.reduce((sum, itemReduce) => itemReduce.mes === 1 ? parseFloat(itemReduce.colhido) : sum, 0),
            fevereiro: item.reduce((sum, itemReduce) => itemReduce.mes === 2 ? parseFloat(itemReduce.colhido) : sum, 0),
            marco: item.reduce((sum, itemReduce) => itemReduce.mes === 3 ? parseFloat(itemReduce.colhido) : sum, 0),
            abril: item.reduce((sum, itemReduce) => itemReduce.mes === 4 ? parseFloat(itemReduce.colhido) : sum, 0),
            maio: item.reduce((sum, itemReduce) => itemReduce.mes === 5 ? parseFloat(itemReduce.colhido) : sum, 0),
            junho: item.reduce((sum, itemReduce) => itemReduce.mes === 6 ? parseFloat(itemReduce.colhido) : sum, 0),
            julho: item.reduce((sum, itemReduce) => itemReduce.mes === 7 ? parseFloat(itemReduce.colhido) : sum, 0),
            agosto: item.reduce((sum, itemReduce) => itemReduce.mes === 8 ? parseFloat(itemReduce.colhido) : sum, 0),
            setembro: item.reduce((sum, itemReduce) => itemReduce.mes === 9 ? parseFloat(itemReduce.colhido) : sum, 0),
            outubro: item.reduce((sum, itemReduce) => itemReduce.mes === 10 ? parseFloat(itemReduce.colhido) : sum, 0),
            novembro: item.reduce((sum, itemReduce) => itemReduce.mes === 11 ? parseFloat(itemReduce.colhido) : sum, 0),
            dezembro: item.reduce((sum, itemReduce) => itemReduce.mes === 12 ? parseFloat(itemReduce.colhido) : sum, 0),
            total: item.reduce((sum, itemReduce) => parseFloat(itemReduce.colhido) + sum, 0),
          },
        })
      })

      return colhido
    },
  },
  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
  },

  mounted () {
    this.getData()

    this.$refs.refRetiradasAnuaisTable.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.refRetiradasAnuaisTable.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    getData: function () {
      if (this.loading) {
        return
      }

      this.dataOfertado = []
      this.dataColhido = []
      this.loading = true
      this.loading1 = true
      this.loading2 = true

      const agrupamento = this.filtros.agrupamento === 0 ? 'talhao' : 'variedade'

      ApiService
        .get(
          '/agricola/retiradas/anuais/' + agrupamento + '/colhido',
          '?' + this.$stringFormat.queryParamsRecursive({
            empresa_id: this.filtros.empresaId,
            producao_id: this.filtros.producaoId,
            data: this.filtros.dataOferta,
            inativo: this.filtros.inativo,
          }),
        )
        .then((res) => {
          this.dataColhido = res.data.data

          this.loading1 = false
          this.loading = this.loading1 || this.loading2

          if (!this.loading && this.filtros.agrupamento === 0 && this.filtros.producaoId < 1) {
            this.collapseAllGroups()
          }
        })
        .catch(() => {
          this.loading1 = false
          this.loading = this.loading1 || this.loading2

          if (!this.loading && this.filtros.agrupamento === 0 && this.filtros.producaoId < 1) {
            this.collapseAllGroups()
          }
        })

      ApiService
        .get(
          '/agricola/retiradas/anuais/' + agrupamento + '/ofertado',
          '?' + this.$stringFormat.queryParamsRecursive({
            empresa_id: this.filtros.empresaId,
            producao_id: this.filtros.producaoId,
            data_oferta: this.filtros.dataOferta,
            inativo: this.filtros.inativo,
          }),
        )
        .then((res) => {
          this.dataOfertado = res.data.data
          this.lastUpdate = res.data.meta

          this.loading2 = false
          this.loading = this.loading1 || this.loading2

          if (!this.loading && this.filtros.agrupamento === 0 && this.filtros.producaoId < 1) {
            this.collapseAllGroups()
          }
        })
        .catch(() => {
          this.loading2 = false
          this.loading = this.loading1 || this.loading2

          if (!this.loading && this.filtros.agrupamento === 0 && this.filtros.producaoId < 1) {
            this.collapseAllGroups()
          }
        })
    },
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
    ordenaTalhao (item1, item2) {
      if (item1.empresa_id > item2.empresa_id) {
        return 1
      }

      if (item1.empresa_id < item2.empresa_id) {
        return -1
      }

      if (item1.producao > item2.producao) {
        return 1
      }

      if (item1.producao < item2.producao) {
        return -1
      }

      if (item1.talhao > item2.talhao) {
        return 1
      }

      if (item1.talhao < item2.talhao) {
        return -1
      }

      if (item1.data > item2.data) {
        return 1
      }

      if (item1.data < item2.data) {
        return -1
      }

      return 0
    },
    filtraPorNome (nome) {
      if (this.filtros.agrupamento === 0) {
        return this.dataTabela.filter((item) => item.producao === nome)
      }

      return this.dataTabela.filter((item) => item.variedade === nome)
    },
    calculaArea (nome) {
      return this.filtraPorNome(nome).reduce((sum, item) => sum + parseFloat(item.area), 0)
    },
    calculaTotal (nome) {
      const total = this.filtraPorNome(nome).reduce((sum, item) => ({
        total: {
          ofertado: sum.total.ofertado + item.ofertado.total,
          colhido: item.colhido ? sum.total.colhido + item.colhido.total : sum.total.colhido,
        },
      }), {
        total: {
          ofertado: 0,
          colhido: 0,
        },
      })

      const areaTotal = this.calculaArea(nome)

      total.percentual = {
        ofertado: total.total.ofertado / areaTotal * 100,
        colhido: total.total.colhido / areaTotal * 100,
      }

      return total
    },
    calculaMeses (nome) {
      return this.filtraPorNome(nome).reduce((sum, item) => ({
        janeiro: { ofertado: sum.janeiro.ofertado + item.ofertado.janeiro, colhido: item.colhido ? sum.janeiro.colhido + item.colhido.janeiro : sum.janeiro.colhido },
        fevereiro: { ofertado: sum.fevereiro.ofertado + item.ofertado.fevereiro, colhido: item.colhido ? sum.fevereiro.colhido + item.colhido.fevereiro : sum.janeiro.colhido },
        marco: { ofertado: sum.marco.ofertado + item.ofertado.marco, colhido: item.colhido ? sum.marco.colhido + item.colhido.marco : sum.janeiro.colhido },
        abril: { ofertado: sum.abril.ofertado + item.ofertado.abril, colhido: item.colhido ? sum.abril.colhido + item.colhido.abril : sum.janeiro.colhido },
        maio: { ofertado: sum.maio.ofertado + item.ofertado.maio, colhido: item.colhido ? sum.maio.colhido + item.colhido.maio : sum.janeiro.colhido },
        junho: { ofertado: sum.junho.ofertado + item.ofertado.junho, colhido: item.colhido ? sum.junho.colhido + item.colhido.junho : sum.janeiro.colhido },
        julho: { ofertado: sum.julho.ofertado + item.ofertado.julho, colhido: item.colhido ? sum.julho.colhido + item.colhido.julho : sum.janeiro.colhido },
        agosto: { ofertado: sum.agosto.ofertado + item.ofertado.agosto, colhido: item.colhido ? sum.agosto.colhido + item.colhido.agosto : sum.janeiro.colhido },
        setembro: { ofertado: sum.setembro.ofertado + item.ofertado.setembro, colhido: item.colhido ? sum.setembro.colhido + item.colhido.setembro : sum.janeiro.colhido },
        outubro: { ofertado: sum.outubro.ofertado + item.ofertado.outubro, colhido: item.colhido ? sum.outubro.colhido + item.colhido.outubro : sum.janeiro.colhido },
        novembro: { ofertado: sum.novembro.ofertado + item.ofertado.novembro, colhido: item.colhido ? sum.novembro.colhido + item.colhido.novembro : sum.janeiro.colhido },
        dezembro: { ofertado: sum.dezembro.ofertado + item.ofertado.dezembro, colhido: item.colhido ? sum.dezembro.colhido + item.colhido.dezembro : sum.janeiro.colhido },
      }), {
        janeiro: { ofertado: 0, colhido: 0 },
        fevereiro: { ofertado: 0, colhido: 0 },
        marco: { ofertado: 0, colhido: 0 },
        abril: { ofertado: 0, colhido: 0 },
        maio: { ofertado: 0, colhido: 0 },
        junho: { ofertado: 0, colhido: 0 },
        julho: { ofertado: 0, colhido: 0 },
        agosto: { ofertado: 0, colhido: 0 },
        setembro: { ofertado: 0, colhido: 0 },
        outubro: { ofertado: 0, colhido: 0 },
        novembro: { ofertado: 0, colhido: 0 },
        dezembro: { ofertado: 0, colhido: 0 },
      })
    },
    ordenaVariedade (item1, item2) {
      if (item1.variedade > item2.variedade) {
        return 1
      }

      if (item1.variedade < item2.variedade) {
        return -1
      }

      if (item1.data > item2.data) {
        return 1
      }

      if (item1.data < item2.data) {
        return -1
      }

      return 0
    },
    getMonthCorteOfertado () {
      const dataFiltro = this.$date.getDateByDateString(this.filtros.dataOferta)
      const hoje = new Date()
      const year = dataFiltro.getFullYear()

      if (year > hoje.getFullYear()) {
        return 0
      }

      if (dataFiltro.getMonth() === 11 && dataFiltro.getDate() === 31) {
        return 12
      }

      const month = this.$date.monthDiff(new Date(year, 0, 1), dataFiltro)
      const monthHoje = this.$date.monthDiff(new Date(year, 0, 1), hoje)
      return month > monthHoje ? monthHoje : month
    },
    gerarOfertado (ofertado, colhido) {
      const linha = Object.assign({
        ofertado: { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, total: 0 },
        colhido: { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, total: 0 },
      }, ofertado, colhido)

      if (!this.filtros.colhido) {
        linha.ofertado = linha.oferta
        return linha
      }

      const month = this.getMonthCorteOfertado()
      const meses = Object.keys(ofertado.oferta)

      let total = 0

      for (const mes in linha.ofertado) {
        if (mes === 'total') {
          linha.ofertado[mes] = total
          continue
        }

        linha.ofertado[mes] = (meses.indexOf(mes) < month) ? linha.colhido[mes] : linha.oferta[mes]
        total += linha.ofertado[mes]
      }

      return linha
    },
    collapseAllGroups () {
      this.$nextTick(() => {
        const refTable = this.$refs.refRetiradasAnuaisTable
        const keys = Object.keys(refTable.$vnode.componentInstance.openCache)

        keys.forEach((x) => {
          refTable.$vnode.componentInstance.openCache[x] = false
        })
      })
    },
  },
}
</script>

<style>
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.bg-purple {
  background-color: purple;
  color: #fff;
}
.bg-cotando {
  background-color: #1F3853;
  color: #fff;
}
.bg-pendente-cotacao {
  background-color: #3F6883;
  color: #fff;
}
.bg-pendente {
  background-color: #FDD01C;
  color: #000;
}
.bg-urgente {
  background-color: #E36968;
  color: #fff;
}
.bg-aprovada {
  background-color: #0AA699;
  color: #fff;
}
.bg-cotada {
  background-color: #0090D9;
  color: #fff;
}
.bg-comprada {
  background-color: #D1DADE;
  color: #000;
}
.bg-entregue {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
.label.label-lg.label-inline {
  width: 100%;
}
.custom-switch,
.custom-switch .v-input__slot,
.custom-switch label {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-switch .v-input--selection-controls__ripple {
  display: none !important;
}
</style>

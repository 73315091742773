<template>
  <div>
    <custom-snackbar ref="snackbar" />
    <ActionsComponent v-model="headers" :filtro="filtros" :headers-options="headersOptions" relatorio-excel="RelatorioRetiradasAnuais" local-storage-save-name="relatorio-retiradas-anuais"/>
    <FilterComponent v-model="filtros" />
    <ListComponents :filtros="filtros" :headers="headers"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponents from './components/ListComponents'

export default {
  components: { ActionsComponent, FilterComponent, ListComponents },
  data: (instance) => {
    return {
      filtros: {
        empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
        producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
        dataOferta: instance.filtroValue(instance.$route.query.dataOferta, instance.$date.getDateNowIso()),
        colhido: instance.filtroValue(instance.$route.query.colhido, true, 'bool'),
        inativo: instance.filtroValue(instance.$route.query.inativo, false, 'bool'),
        agrupamento: instance.filtroValue(instance.$route.query.agrupamento, 0, 'int'),
      },
      headers: [],
    }
  },
  computed: {
    headersOptions () {
      return [
        { text: 'Talhão', value: 'talhao', align: 'center', class: 'sticky-header custom-table--header', total: 'title', obrigatorio: this.filtros.agrupamento === 0, hidden: this.filtros.agrupamento === 1 },
        { text: 'Variedade', value: 'variedade', align: 'center', class: 'sticky-header custom-table--header', total: 'title', obrigatorio: this.filtros.agrupamento === 1, hidden: this.filtros.agrupamento === 0 },

        { text: 'Area (m²)', value: 'area', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => this.$stringFormat.formatNumber(total, 0) },
        { text: 'Retiradas', value: 'retiradas', align: 'center', class: 'sticky-header custom-table--header', ativado: true, total: 'text' },

        { text: 'JAN', value: 'janeiro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.janeiro + sum.oferta, colhido: item.colhido ? item.colhido.janeiro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'FEV', value: 'fevereiro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.fevereiro + sum.oferta, colhido: item.colhido ? item.colhido.fevereiro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'MAR', value: 'marco', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.marco + sum.oferta, colhido: item.colhido ? item.colhido.marco + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'ABR', value: 'abril', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.abril + sum.oferta, colhido: item.colhido ? item.colhido.abril + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'MAI', value: 'maio', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.maio + sum.oferta, colhido: item.colhido ? item.colhido.maio + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'JUN', value: 'junho', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.junho + sum.oferta, colhido: item.colhido ? item.colhido.junho + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'JUL', value: 'julho', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.julho + sum.oferta, colhido: item.colhido ? item.colhido.julho + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'AGO', value: 'agosto', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.agosto + sum.oferta, colhido: item.colhido ? item.colhido.agosto + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'SET', value: 'setembro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.setembro + sum.oferta, colhido: item.colhido ? item.colhido.setembro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'OUT', value: 'outubro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.outubro + sum.oferta, colhido: item.colhido ? item.colhido.outubro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'NOV', value: 'novembro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.novembro + sum.oferta, colhido: item.colhido ? item.colhido.novembro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'DEZ', value: 'dezembro', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.dezembro + sum.oferta, colhido: item.colhido ? item.colhido.dezembro + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },
        { text: 'Total', value: 'total', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ oferta: item.ofertado.total + sum.oferta, colhido: item.colhido ? item.colhido.total + sum.colhido : sum.colhido }), { oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.oferta, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.colhido, 0) + '</strong>' },

        { text: 'Percentual', value: 'percentual', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum_object', reduceObject: (data) => data.reduce((sum, item) => ({ area: item.area + sum.area, oferta: item.ofertado.total + sum.oferta, colhido: item.colhido ? item.colhido.total + sum.colhido : sum.colhido }), { area: 0, oferta: 0, colhido: 0 }), totalFormat: (total) => this.$stringFormat.formatNumber(total.area > 0 ? total.oferta / total.area * 100 : 0, 0) + '<br><strong>' + this.$stringFormat.formatNumber(total.area > 0 ? total.colhido / total.area * 100 : 0, 0) + '</strong>' },
      ]
    },
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Relatório de Retiradas Anuais' },
    ])
  },
  watch: {
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      if (type === 'bool') {
        return value === 'true'
      }

      return value
    },
  },
}
</script>

<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-6 col-lg-6">
          <EmpresasSelect v-model="empresaId" @change="change = true" mostra-todos-label></EmpresasSelect>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-6">
          <ProducoesSelect v-model="producaoId" :empresa="empresaId" :disabled="!empresaId" @change="change = true" mostra-todos-label></ProducoesSelect>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-3">
          <DatePicker label="Data Oferta" v-model="dataOferta"/>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-3">
          <v-switch inset class="mt-1" label="Oferta fechada como colhido" :model-value="true" v-model="colhido"></v-switch>
        </v-col>
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <v-select
            dense
            outlined
            item-text="label"
            item-value="value"
            label="Agrupamento"
            v-model="agrupamento"
            :items="agrupamentosOptions"
            @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-2 col-lg-1">
          <v-switch inset class="mt-1" label="Inativos" :model-value="true" v-model="inativo"></v-switch>
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresaId: '',
      producaoId: '',
      dataOferta: '',
      colhido: true,
      inativo: false,
      agrupamento: 0,
      agrupamentosOptions: [
        { value: 0, label: 'Talhão' },
        { value: 1, label: 'Variedade' },
      ],
    }
  },
  mounted () {
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.dataOferta = this.value?.dataOferta
    this.colhido = this.value?.colhido
    this.inativo = this.value?.inativo
    this.agrupamento = parseInt(this.value?.agrupamento) ?? 0
  },
  watch: {
    value (val) {
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.dataOferta = val?.dataOferta
      this.colhido = val?.colhido
      this.inativo = val?.inativo
      this.agrupamento = parseInt(val?.agrupamento) ?? 0
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        dataOferta: this.dataOferta,
        colhido: this.colhido,
        inativo: this.inativo,
        agrupamento: this.agrupamento,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
